import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LogoSin from "../Imagenes/Icono_morado.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { VerificarPersonalAcreditado } from "../../APIS/api";

const PersonalAcreditado = () => {
  const [datosConductor, setDatosConductor] = useState({});
  const [esAcreditado, setEsAcreditado] = useState(null); // Estado para verificar si es acreditado
  const [loading, setLoading] = useState(true); // Estado de carga
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const datos = {
      nombre: queryParams.get("nombre"),
      cargo: queryParams.get("cargo"),
      dni: queryParams.get("dni"),
    };
    if (!datos.nombre || !datos.cargo || !datos.dni) {
      navigate('/'); // Redirigir a Home si alguno de los datos es faltante
    } else {
      setDatosConductor(datos);
      verificarYRedirigir(datos);
    }
  }, [location, navigate]);

  const verificarYRedirigir = async (datos) => {
    setLoading(true); // Iniciar estado de carga
    const { nombre } = datos;
    const resultado = await VerificarPersonalAcreditado(nombre);
    setEsAcreditado(resultado);
    setLoading(false); // Finalizar estado de carga
  };

  const { nombre, cargo, dni } = datosConductor;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: `url(${LogoSin}) center / 10%  rgba(17, 25, 42, 0.2)`, // Establece el fondo con el SVG
        }}
      >
        <Box
          component={Paper}
          elevation={3}
          sx={{
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: 2,
            width: '300px',
            textAlign: 'center'
          }}
        >
          {loading ? (
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Verificando...
            </Typography>
          ) : esAcreditado === true ? (
            <CheckCircleIcon sx={{ fontSize: 120, color: 'green', mb: 2 }} />
          ) : esAcreditado === false ? (
            <CancelIcon sx={{ fontSize: 120, color: 'red', mb: 2 }} />
          ) : (
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              No se pudo verificar
            </Typography>
          )}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            PERSONAL AUTORIZADO POR TRANSIT
          </Typography>
          <Typography variant="body1">
            Nombre: {nombre}
          </Typography>
          <Typography variant="body1">
            Cargo: {cargo}
          </Typography>
          <Typography variant="body1">
            DNI: {dni}
          </Typography>
        </Box>
      </div>
    </>
  );
}

export default PersonalAcreditado;