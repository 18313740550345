import React, { useState, useEffect } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import FloatingWhatsAppButton from "./wsp";
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  IconButton,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GuardarDatosProveedor, VerificarTicket } from "../../APIS/api";

const Proveedor = () => {
  const [ticket, setTicket] = useState("");
  const [numPasajeros, setNumPasajeros] = useState(1);
  const [pasajeros, setPasajeros] = useState([{ nombre: "", telefono: "", FechaRecogida: "", TiempoRecogida: "", TicketVuelo: "" }]);
  const [Datos, setDatos] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (numPasajeros > 1) {
      const newPasajeros = pasajeros.map((pasajero, index) => {
        if (index > 0) {
          if (pasajero.repetirFecha) {
            pasajero.FechaRecogida = pasajeros[index - 1].FechaRecogida;
          }
          if (pasajero.repetirHora) {
            pasajero.TiempoRecogida = pasajeros[index - 1].TiempoRecogida;
          }
        }
        return pasajero;
      });
      setPasajeros(newPasajeros);
    }
  }, [numPasajeros, pasajeros]);

  const handleNumPasajerosChange = (event) => {
    const num = parseInt(event.target.value, 10);
    setNumPasajeros(num);
    setPasajeros(
      Array.from({ length: num }, (_, index) => pasajeros[index] || { nombre: "", telefono: "", FechaRecogida: "", TiempoRecogida: "", TicketVuelo: "" })
    );
  };

  const handlePasajeroChange = (index, field, value) => {
    const newPasajeros = [...pasajeros];
    newPasajeros[index][field] = value;
    setPasajeros(newPasajeros);
  };

  const removePasajero = (index) => {
    const newPasajeros = [...pasajeros];
    newPasajeros.splice(index, 1);
    setPasajeros(newPasajeros);
    setNumPasajeros(newPasajeros.length);
  };

  const verificarTicket = async (ticket) => {
    try {
      const existe = await VerificarTicket(ticket);
      return existe;
    } catch (error) {
      console.error("Error al verificar el ticket:", error);
      return false;
    }
  };

  const GuardarDatosPagina = async () => {
    const ticketExiste = await verificarTicket(ticket);
    if (ticketExiste) {
      setError("El ticket ya está creado. Por favor utiliza otro.");
      return;
    }

    for (let i = 0; i < pasajeros.length; i++) {
      const pasajero = pasajeros[i];
      if (!pasajero.nombre || !pasajero.telefono || !pasajero.FechaRecogida || !pasajero.TiempoRecogida || !pasajero.TicketVuelo) {
        alert('Por favor completa todos los campos obligatorios.');
        return;
      }
    }

    const Datos = {
      ticket,
      pasajeros
    };
    setDatos(Datos);
    await GuardarDatosProveedor(ticket, Datos);

    // Reset the form and show success message
    setTicket("");
    setNumPasajeros(1);
    setPasajeros([{ nombre: "", telefono: "", FechaRecogida: "", TiempoRecogida: "", TicketVuelo: "" }]);
    setSuccess(true);
  };

  return (
    <>
      <NavbarL />
      <FloatingWhatsAppButton />
      <br />
      <br />
      <Box sx={{ margin: 'auto', maxWidth: 500 }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h4" component="h1" sx={{ textAlign: 'center', marginBottom: 2 }}>
            Ingresar Datos
          </Typography>
          {error && <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>{error}</Typography>}
          <TextField
            label="Ingresar Ticket"
            placeholder="SE-0000"
            type="text"
            sx={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            value={ticket}
            onChange={(e) => {
              setTicket(e.target.value);
              setError("");
            }}
          />
          <p />
          <TextField
            label="Cantidad de Pasajeros"
            placeholder="1"
            type="number"
            value={numPasajeros}
            onChange={handleNumPasajerosChange}
            sx={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <p />
          {pasajeros.map((pasajero, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography>Pasajero {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={`Nombre del Pasajero ${index + 1}`}
                      placeholder="Nombre"
                      type="text"
                      value={pasajero.nombre}
                      onChange={(e) => handlePasajeroChange(index, 'nombre', e.target.value)}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      country={'cl'}
                      value={pasajero.telefono}
                      onChange={(value) => handlePasajeroChange(index, 'telefono', value)}
                      inputStyle={{ width: "100%", height: "55px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={`Numero del Vuelo ${index + 1}`}
                      placeholder="LATAM 123456789"
                      type="text"
                      value={pasajero.TicketVuelo}
                      onChange={(e) => handlePasajeroChange(index, 'TicketVuelo', e.target.value)}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={`Fecha de recogida ${index + 1}`}
                      type="date"
                      value={pasajero.FechaRecogida}
                      onChange={(e) => handlePasajeroChange(index, 'FechaRecogida', e.target.value)}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={`Hora de recogida ${index + 1}`}
                      type="time"
                      value={pasajero.TiempoRecogida}
                      onChange={(e) => handlePasajeroChange(index, 'TiempoRecogida', e.target.value)}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                      aria-label="eliminar"
                      color="error"
                      onClick={() => removePasajero(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '100%', marginTop: 3 }}
            onClick={GuardarDatosPagina}
          >
            ¡Guardar!
          </Button>
        </Paper>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <TerminoPag />
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Guardado con éxito
        </Alert>
      </Snackbar>
    </>
  );
};

export default Proveedor;
