import React, { useEffect, useState, useRef } from "react";
import NavbarL from "../Loyouts/Navbar";
import TerminoPag from "../Termino de pagina/TerminoPag";
import {
  Grid,
  Box,
  Card,
  IconButton,
  Typography,
  Button,
  Container,
  CardContent,
  TextField,
  Stack,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  Autocomplete,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";

import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import webpay from "./../Imagenes/webpay-logo-1.png";



import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import PropTypes from 'prop-types';
import FloatingWhatsAppButton from "./wsp";
import { GuardarDatosPaginaWeb, ObtenerTodaslasSolicitud, ObtenerDatosTarifasIDComuna, ObtenerDatosTarifasIDComunaValores } from "../../APIS/api";
import { crearTransaccion } from "../../APIS/apiTransbank";
import { v4 as uuidv4 } from 'uuid';

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 1
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red"
  }
});

const Reservas = ({ datos, handleData }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [id, setID] = useState("");
  const sessionId = uuidv4();

  const [origen, setOrigen] = useState("");
  const [destino, setDestino] = useState("");

  const [horaRecogida, setHoraRecogida] = useState("");
  const [fechaRecogida, setFechaRecogida] = useState("");
  const [pax, setPax] = useState("");
  const [vehiculo, setVehiculo] = useState("");

  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [comunaOrigen, setComunaOrigen] = useState("");
  const [comunaDestino, setComunaDestino] = useState("");
  const [comunasL, setComunasL] = useState([]);
  const [tarifaOrigen, setTarifaOrigen] = useState([]);
  const [tarifaDestino, setTarifaDestino] = useState([]);
  const [total, setTotal] = useState(0);

  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const stepss = ['Paso 1 de 4', 'Paso 2 de 4', 'Paso 3 de 4', 'Paso 4 de 4'];
  const stepnumber = activeStep + 0;

  useEffect(() => {
    if (datos && datos.origen && origen !== datos.origen) {
      setOrigen(datos.origen);
      setDestino(datos.destino);
      setFechaRecogida(datos.fechaRecogida);
      setPax(datos.pax);
      setHoraRecogida(datos.horaRecogida);
    }
  }, [datos, origen]);

  const VehiculosChile = [
    { id: 1, label: 'VAN' },
  ];

  const VehiculosPeru = [
    { id: 1, label: 'VAN' },
    { id: 2, label: 'SUV' },
    { id: 3, label: 'MINI BUS' },
    { id: 4, label: 'BUS' },
  ];

  useEffect(() => {
    const cargarDatosComuna = async () => {
      const datosComuna = await ObtenerDatosTarifasIDComuna();
      const comunas = datosComuna.map(({ comuna }) => comuna);
      setComunasL(comunas);
    };
    cargarDatosComuna();
  }, []);

  useEffect(() => {
    const cargarDatosValores = async (comunaOrigen) => {
      const datosValoresOrigen = await ObtenerDatosTarifasIDComunaValores(comunaOrigen);
      const valoresOrigen = datosValoresOrigen.map(({ opaca }) => ({ opaca }));
      if (valoresOrigen[0]?.opaca?.oAlta) {
        setTarifaOrigen(valoresOrigen[0].opaca.oAlta);
      }
    };
    cargarDatosValores(comunaOrigen);
  }, [comunaOrigen]);

  useEffect(() => {
    const cargarDatosValores = async (comunaDestino) => {
      const datosValoresDestino = await ObtenerDatosTarifasIDComunaValores(comunaDestino);
      const valoreDestinos = datosValoresDestino.map(({ opaca }) => ({ opaca }));
      if (valoreDestinos[0]?.opaca?.oAlta) {
        setTarifaDestino(valoreDestinos[0].opaca.oAlta);
      }
    };
    cargarDatosValores(comunaDestino);
  }, [comunaDestino]);

  useEffect(() => {
    if (!comunaOrigen || !comunaDestino) {
      setTotal(0);
      return;
    }
    const total = (parseInt(tarifaOrigen) + parseInt(tarifaDestino));
    const valorFinal = Math.round(total / 2);
    setTotal(valorFinal);
  }, [comunaOrigen,comunaDestino,pax]);

  const DatosCliente = {
    nombre,
    telefono,
    correo,
    mensaje,
  }

  const Viaje = {
    origen,
    comunaOrigen,
    destino,
    comunaDestino,
    horaRecogida,
    fechaRecogida,
    vehiculo,
    pax,
    total
  }

  const Datos = {
    Viaje,
    DatosCliente,
  }



  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handlePaxChange = (e) => {
    const newValue = e.target.value;
    // Permitir solo números entre 1 y 7
    if (newValue === "" || (parseInt(newValue) >= 1 && parseInt(newValue) <= 7)) {
      setPax(newValue);
    }
  };


  useEffect(() => {
    if (!stepss) {
      // console.log("No hay tarjeta seleccionada");
    } else {
      const unsubscribe = ObtenerTodaslasSolicitud("PaginaWeb", (ID) => {
        setID(`WEB-${ID.toString()}`);
      });
      return () => unsubscribe();
    }
  }, [stepss]);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient(95deg, #8c52fe 0%, #8c52fe 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <AddLocationAltOutlinedIcon />,
      2: <CalendarMonthOutlinedIcon />,
      3: <PeopleAltOutlinedIcon />,
      4: <NoCrashIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const steps = ['Origen/Destino', 'Fecha/Vehiculo', 'Datos personales', 'Confirmación/Pago'];

  // useEffect(() => {
  //   const contar = async () => {
  //     const newID = await ObtenerDatosPaginaWeb();
  //     setID(newID);
  //   };
  //   contar();
  // }, [open]);


  // console.log(id, "ID");

  const mostrarAlertaFaltaInformacion = () => {
    setMostrarAlerta(true);
  };

  const mostrarAlertaGuardadoExito = () => {
    setMostrarAlertaExito(true);
  };

  useEffect(() => {
    if (pax > 0 && vehiculo) {
      if (vehiculo === "VAN") {
        const gruposDeVAN = Math.ceil(pax / 7);
        setTotal(total * gruposDeVAN);
      } else if (vehiculo === "SUV") {
        const gruposDeSUV = Math.ceil(pax / 3);
        setTotal(total * gruposDeSUV);
      }
    }
  }, [pax, vehiculo]);

  



  const GuardarDatosPagina = async () => {
    if (
      origen &&
      destino &&
      comunaOrigen &&
      comunaDestino &&
      fechaRecogida &&
      horaRecogida &&
      vehiculo &&
      nombre &&
      telefono &&
      correo
    ) {
      await GuardarDatosPaginaWeb(id, Datos);
      mostrarAlertaGuardadoExito();
      setOrigen("");
      setDestino("");
      setComunaOrigen("");
      setComunaDestino("");
      setFechaRecogida("");
      setHoraRecogida("");
      setVehiculo("");
      setPax("");
      setNombre("");
      setTelefono("");
      setCorreo("");
      setMensaje("");
      setActiveStep(0);
      return true; // Datos guardados con éxito
    } else {
      mostrarAlertaFaltaInformacion();
      return false; // Falta información
    }
  };

  const iniciarTransaccion = async () => {
    const buyOrder = `${id}-${Date.now()}`;
    const returnUrl = "https://www.transitchile.cl/Proceso";
    const errorUrl = "https://www.transitchile.cl/Error"; // URL para casos de error
    const value = total

    try {
      const data = await crearTransaccion(value, sessionId, buyOrder, returnUrl, errorUrl);
      if (data && data.url && data.token) {
        window.location.href = data.url + "?token_ws=" + data.token;
      } else {
        window.location.href = errorUrl;
      }
    } catch (error) {
      window.location.href = errorUrl;
    }
  };


  return (
    <>
      <NavbarL />
      <FloatingWhatsAppButton />
      <Box>
        <Container maxWidth="xl">
          <br />
          <Grid container justifyContent="center" alignItems="center" xs={12} md={12}>
            <Typography
              color="#8c52fe"
              fontWeight="bold"
              sx={{
                fontSize: '40px',
                textShadow: '1.5px 1.5px 1.5px #6200ea'
              }}
            >
              Reservas
            </Typography>
          </Grid>
          <Grid container xs={12} md={12} >
            <Grid  container  alignItems="center" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} md={8} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Container component="main" maxWidth="xl">
                  <Paper sx={{ padding: 2, marginTop: 5, borderRadius: 3, bgcolor: '#f5f5f5' }}>
                    <Paper sx={{ padding: -2, bgcolor: '#d1c4e9', padding: '10px', borderRadius: '10px' }}>
                      <Typography variant="body1" color="text.secondary" textAlign="left" fontWeight='bold' sx={{ ml: 1 }}>
                        Debe selecionar destino y origen:
                      </Typography>
                    </Paper>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                        Origen
                      </Typography>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={2} justifyContent="center">
                          <Grid item xs={12} md={6} >
                            <TextField
                              id="outlined-required"
                              placeholder="Aeropuerto"
                              type="text"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={origen}
                              error={!origen}
                              onChange={(e) => setOrigen(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Autocomplete
                              id="outlined-required"
                              options={comunasL}
                              value={comunaOrigen}
                              onChange={(event, newValue) => setComunaOrigen(newValue)}
                              error={!comunasL}
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              renderInput={(params) => (
                                <ValidationTextField
                                  required
                                  error={!comunaOrigen}
                                  {...params}
                                  label="Comuna/Distrito Origen"
                                  style={{ color: 'white', width: '100%' }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                        Destino
                      </Typography>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={2} justifyContent="center">
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              placeholder="Santiago"
                              type="text"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={destino}
                              error={!destino}
                              onChange={(e) => setDestino(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Autocomplete
                              id="autocomplete-comuna"
                              options={comunasL}
                              value={comunaDestino}
                              onChange={(event, newValue) => setComunaDestino(newValue)}
                              error={!comunasL}
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              renderInput={(params) => (
                                <ValidationTextField
                                  required
                                  error={!comunaDestino}
                                  {...params}
                                  label="Comuna/Distrito Destino"
                                  style={{ color: 'white', width: '100%' }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Container>
                <Container component="main" maxWidth="xl">
                  <Paper sx={{ padding: 2, marginTop: 5, borderRadius: 3, bgcolor: '#f5f5f5' }}>
                    <Paper sx={{ padding: -2, bgcolor: '#d1c4e9', padding: '10px', borderRadius: '10px' }}>
                      <Typography variant="body1" color="text.secondary" textAlign="left" fontWeight='bold' sx={{ ml: 1 }}>
                        Debe selecionar Fecha y Tipo de vehiculo:
                      </Typography>
                    </Paper>
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                        Fecha de recogida
                      </Typography>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={2} justifyContent="center">
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              type="date"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={fechaRecogida}
                              error={!fechaRecogida}
                              onChange={(e) => setFechaRecogida(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              type="time"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={horaRecogida}
                              error={!horaRecogida}
                              onChange={(e) => setHoraRecogida(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={2} justifyContent="center">
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              type="number"
                              label="Cantidad de personas"
                              placeholder="4"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={pax}
                              error={!pax || parseInt(pax) < 1 || parseInt(pax) > 7}
                              onChange={handlePaxChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Autocomplete
                              options={VehiculosChile}
                              style={{ width: "100%" }}
                              value={vehiculo}
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(event, newValue) => { setVehiculo(newValue.label) }}
                              renderInput={(params) => (
                                <TextField
                                  error={!vehiculo}
                                  {...params}
                                  label="Tipo de Vehiculo"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Container>
                <Container component="main" maxWidth="xl">
                  <Paper sx={{ padding: 2, marginTop: 5, borderRadius: 3, bgcolor: '#f5f5f5' }}>
                    <Paper sx={{ padding: -2, bgcolor: '#d1c4e9', padding: '10px', borderRadius: '10px' }}>
                      <Typography variant="body1" color="text.secondary" textAlign="left" fontWeight='bold' sx={{ ml: 1 }}>
                        Ingrese sus datos personales:
                      </Typography>
                    </Paper>
                    <Box sx={{ marginTop: 2 }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={2} justifyContent="center">
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              label="Nombre y apellido"
                              type="text"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={nombre}
                              error={!nombre}
                              onChange={(e) => setNombre(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              label="Telefono"
                              type="text"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={telefono}
                              error={!telefono}
                              onChange={(e) => setTelefono(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={2} justifyContent="center">
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              label="Correo"
                              type="text"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={correo}
                              error={!correo}
                              onChange={(e) => setCorreo(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <TextField
                              id={`outlined-required-`}
                              label="Mensaje"
                              type="text"
                              sx={{
                                width: "100%",
                                backgroundColor: 'white',
                                borderRadius: '15px',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={mensaje}
                              onChange={(e) => setMensaje(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Container>
              </Grid>
            <Grid item xs={12} md={4} container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Container component="main" maxWidth="xl">
              <Paper sx={{ padding: 2, marginTop: 5, borderRadius: 5 }}>
                
              <Card
                sx={{
                  height: isMobile ? 'auto' : "100%",
                  width: '100%',
                  borderRadius: '20px',
                }}
              >
                <CardContent>
                  <Typography color="#8c52fe" fontWeight="bold" variant="h6" >
                    Detalle de tu servicio:
                  </Typography>
                  <Grid sx={{ marginBottom: 1, marginTop: 1, border: "0.5px solid #eeeeee", display: "flex", alignItems: "center" }} />
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        Origen:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {origen} - {comunaOrigen}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        Destino:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {destino} - {comunaDestino}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        Fecha recogida:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {fechaRecogida} - {horaRecogida}
                      </Typography>
                    </Grid>
                  </Grid>
                  <i />
                  <Grid sx={{ marginBottom: 1, marginTop: 1, border: "0.5px solid #eeeeee", display: "flex", alignItems: "center" }} />
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        Tipo de vehiculo:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {vehiculo}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        C/ Pasajeros:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {pax}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        C/ de maletas:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {pax}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ marginBottom: 1, marginTop: 1, border: "0.5px solid #eeeeee", display: "flex", alignItems: "center" }} />
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        Valor del servicio:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {vehiculo}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ marginBottom: 1, marginTop: 1, border: "0.5px solid #eeeeee", display: "flex", alignItems: "center" }} />
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: 'black',
                          fontWeight: "bold",
                          fontSize: '17px',
                        }}
                      >
                        Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        textAlign="right"
                        sx={{
                          fontWeight: "bold",
                          fontSize: '15px',
                        }}
                      >
                        {total}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br/>
                  <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={12} container spacing={3} justifyContent="center">
                          <Grid item xs={12} md={6} >
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Image width={200} src={webpay} duration={500} />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Typography variant="body2" color="text.secondary" textAlign="left">
                              Se contactaran con usted para confirmar su reserva.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Button
                              color="inherit"
                              sx={{
                                bgcolor: '#8c52fe',
                                color: 'white',
                                padding: '10px',
                                borderRadius: '5px',
                                width: '100%',
                              }}
                              onClick={async () => {
                                const datosGuardados = await GuardarDatosPagina();
                                if (datosGuardados) {
                                  iniciarTransaccion();
                                }
                              }}
                            >
                              <Typography variant="body2" fontWeight="bold">
                                PAGO
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                </CardContent>
              </Card>
              </Paper>
              </Container>
          </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <br />
      <br />
      <br />
      <TerminoPag />
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Guardado con exito!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Reservas;
