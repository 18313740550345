import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAZJ7jCNaAU30Edy29CrsGGPwSdVLtY_qM",
  authDomain: "transit-399da.firebaseapp.com",
  databaseURL: "https://transit-399da-default-rtdb.firebaseio.com",
  projectId: "transit-399da",
  storageBucket: "transit-399da.appspot.com",
  messagingSenderId: "236805317460",
  appId: "1:236805317460:web:1ddd0d2a7b1dda7fef9ec1"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, db, auth, storage };