import React, { useState } from 'react';
import {
  Toolbar,
  Grid,
  Typography,
  Button,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  AppBar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import Logo from "../Imagenes/logo_morado_texto_al_lado.png";

const NavbarL = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { text: 'Inicio', link: '/' },
    { text: 'Quienes Somos', link: '/Quienes-Somos' },
    { text: 'Servicios', link: '/Servicios' },
    { text: 'Tours', link: '/Tours' },
    { text: 'Contacto', link: '/Contacto' },
    { text: 'Proveedor', link: '/Proveedor' },
  ];

  // return (
  //   <Container maxWidth="xl">
  //     <Toolbar>
  //       <Grid container justifyContent="space-between" alignItems="center">
  //         <Grid item xs={6} md={3}>
  //           <Typography variant="h6" component="div">
  //             <img
  //               src={Logo}
  //               alt="Logo"
  //               width="100%"
  //               height="auto"
  //               style={{ marginTop: '15px', marginBottom: '5px' }} // Agregar margen superior e inferior
  //             />
  //           </Typography>
  //         </Grid>
  //         {isSmallScreen ? (
  //           <>
  //             <Grid item xs={6} container justifyContent="flex-end">
  //               <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle}>
  //                 <MenuIcon />
  //               </IconButton>
  //               <Drawer anchor="right" open={isMenuOpen} onClose={handleMenuToggle}>
  //                 <List>
  //                   {menuItems.map((item, index) => (
  //                     <ListItem button key={index} component={Link} to={item.link} onClick={handleMenuToggle}>
  //                       <ListItemText primary={item.text} />
  //                     </ListItem>
  //                   ))}
  //                 </List>
  //                 <ListItem button component={Link} to="/Reservas">
  //                   <ListItemText primary={"Reservas"} />
  //                 </ListItem>
  //                 {/* <ListItem button component={Link} to="/Chat">
  //                   <ListItemText primary={"Chat"} />
  //                 </ListItem> */}
  //               </Drawer>
  //             </Grid>

  //           </>
  //         ) : (
  //           <Grid item container xs={8} justifyContent="flex-end" spacing={1}>
  //             {menuItems.map((item, index) => (
  //               <Grid item key={index}>
  //                 <Button color="inherit" component={Link} to={item.link}>
  //                   <Typography variant="body2" fontWeight="bold">
  //                     {item.text}
  //                   </Typography>
  //                 </Button>
  //               </Grid>
  //             ))}
  //             <Grid item>
  //               <Button
  //                 color="inherit"
  //                 component={Link}
  //                 to="/Reservas"
  //                 sx={{
  //                   bgcolor: '#8c52fe',
  //                   color: 'white',
  //                   padding: '10px',
  //                   borderRadius: '5px',
  //                 }}
  //               >
  //                 <Typography variant="body2" fontWeight="bold">
  //                   Reservas
  //                 </Typography>
  //               </Button>
  //             </Grid>
  //             <Grid item>
  //               <Button
  //                 color="inherit"
  //                 component={Link}
  //                 to="/Convenios"
  //                 sx={{
  //                   bgcolor: '#616161',
  //                   color: 'white',
  //                   padding: '10px',
  //                   borderRadius: '5px',
  //                 }}
  //               >
  //                 <Typography variant="body2" fontWeight="bold">
  //                   Convenios
  //                 </Typography>
  //               </Button>
  //             </Grid>
  //             {/* <Grid item>
  //               <Button
  //                 color="inherit"
  //                 component={Link}
  //                 to="/Chat"
  //                 sx={{
  //                   bgcolor: '#8c52fe',
  //                   color: 'white',
  //                   padding: '10px',
  //                   borderRadius: '5px',
  //                 }}
  //               >
  //                 <Typography variant="body2" fontWeight="bold">
  //                   hablar con el Coordinador
  //                 </Typography>
  //               </Button>
  //             </Grid> */}
  //           </Grid>
  //         )}
  //       </Grid>
  //     </Toolbar>
  //   </Container>
  // );

  return (
    <AppBar position="static"  sx={{ marginBottom: 0, bgcolor: '#8c52fe', }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="h6">
                <img 
                  src={Logo} 
                  alt="Logo" 
                  style={{ width: '80%', height: '80%', marginTop: '10px', marginBottom: '5px' }}
                />
              </Typography>
            </Grid>
            {isSmallScreen ? (
              <>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuToggle} sx={{ marginLeft: 'auto' }}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={isMenuOpen} onClose={handleMenuToggle}>
                  <List>
                    {menuItems.map((item, index) => (
                      <ListItem button key={index} component={Link} to={item.link} onClick={handleMenuToggle}>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    ))}
                    <ListItem button component={Link} to="/Reservas" onClick={handleMenuToggle}>
                      <Button
                        sx={{
                          bgcolor: '#8c52fe',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '5px',
                          width: '100%'
                        }}
                      >
                        Reservas
                      </Button>
                    </ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              <Grid item sm={8} container justifyContent="flex-end" spacing={0}>
                {menuItems.map((item, index) => (
                  <Button key={index} color="inherit" component={Link} to={item.link} sx={{ fontWeight: 'bold' }}>
                    {item.text}
                  </Button>
                ))}
                <Button
                  color="inherit"
                  component={Link}
                  to="/Reservas"
                  sx={{
                    bgcolor: 'white',
                    color: '#8c52fe',
                    padding: '10px',
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="body2" fontWeight="bold">
                  Reservas
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavbarL;
